const type = "Button";

const menuItems = [
  {
    label: "Início",
    type: type,
    to: "inicio",
  },
  {
    label: "Sobre",
    type: type,
    to: "sobre",
  },

  {
    label: "Serviços",
    type: type,
    to: "servicos",
  },
  {
    label: "Contato",
    type: type,
    to: "contato",
  },
];

export default menuItems;
