// Header.js
import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import MenuBars from "../../assets/imgs/menu-bars.svg";
import dani from "../../assets/imgs/dani-logo.svg";
import Menu from "./Menu";
import "./Header.css";
import menuItems from "./Btns-Content";
import MenuBtn from "./MenuBtn";

const Header = ({ scrollToComponent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (
      <div key={index}>
        <MenuBtn
          action={action}
          data={menuItem}
          scrollToComponent={scrollToComponent}
        />
      </div>
    ));
  }, [menuItems]);

  return (
    <div className="header-wrapper">
      <div className="header-container">
        <div className="logo">
          <Link to="/" alt="home">
            <img className="logo-dani" src={dani} alt="danielle" />
          </Link>
        </div>

        <div className="menu-desktop">{memoizedMenuItems}</div>


          <button className="menu-toggle" onClick={toggleSidebar}>
            <img src={MenuBars} alt="Menu Bars" />
          </button>
      </div>
      <Menu
        scrollToComponent={scrollToComponent}
        toggleSidebar={toggleSidebar}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default Header;
