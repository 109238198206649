import React from "react";
import "./Servicos.css";
import servicosData from "./servicos.json"; 
import servicosData2 from "./servicos2.json"; 
import servicosData3 from "./servico3.json"; 

function ServicosCard() {
  const mensagemGestaoCompleta = "Olá! Estou interessado na Gestão Estratégica Completa.";
  const mensagemConsultoria = "Olá! Estou interessado na Consultoria Completa.";
  const mensagemManualStories = "Olá! Estou interessado no Manual Para Stories.";

  return (
    <div className="servicos-wrapper">
      <div className="servicos-card">
        <div className="titulos">
          <h1>Do que você precisa?</h1>
          <h2>Encontre aqui sua solução</h2>
        </div>

        <div className="sessao">
          
          <ul>
           
           
                <div className="servicos">
                  <div className="titulos-cards">
                  <span className="servicos-names"> Gestão Estratégica Completa </span>
                  <p className="subtitulos-cards">Acompanhamento mensal</p>

                  </div>
                  <div className="servicos-conteudo">
                  <p className="p-diferente">• Tenha um planejamento estratégico e agregue valor à sua advocacia com conteúdos relevantes para as suas redes sociais todos os meses.</p>
                  </div>
                  <i>Contratação mínima: 3 meses.</i>

                  <span className="valores" style={{ color: "#AA7049"  }}> Orçamento personalizado.</span>
                  <a className="main-btn2" href={`https://wa.me/5584996174421?text=${encodeURIComponent(mensagemGestaoCompleta)}`}
            target="_blank"
            rel="noreferrer">Quero a Gestão Estratégica Completa</a>
                </div>
                <div className="servicos">
                  <div className="titulos-cards">
                  <span className="servicos-names"> Consultoria </span>
                  <p className="subtitulos-cards">Encontro virtual de até 90 minutos.</p>

                  </div>

                  <div className="servicos-conteudo">

                  <p className="p-diferente">• Receba orientações sobre o seu posicionamento, estruture o seu perfil e aprenda a utilizar as redes sociais de maneira estratégica e 
                  em conformidade com o Código de Ética e o Provimento 205/2021.</p>
                  <span className="valores" style={{ color: "#AA7049"  }}> Orçamento personalizado.</span>

                  

                  </div>
                 

                  <a className="main-btn2" href={`https://wa.me/5584996174421?text=${encodeURIComponent(mensagemConsultoria)}`}
            target="_blank"
            rel="noreferrer">Quero a Consultoria</a>
                </div>
                <div className="servicos">
                  <div className="titulos-cards">
                  <span className="servicos-names"> Manual de Stories </span>
                  <p className="subtitulos-cards">Ebook.</p>

                  </div>
                  <div className="servicos-conteudo">
                  <p className="p-diferente">• Destrave os seus stories com esse e-book com 30 ideias de conteúdo.</p>
                  </div>
                  <span className="valores" style={{ color: "#AA7049"  }}> Orçamento personalizado.</span>

                  

                  <a className="main-btn2" href={`https://wa.me/5584994416676?text=${encodeURIComponent(mensagemManualStories)}`}
            target="_blank"
            rel="noreferrer">Quero o Manual Para Stories</a>
                </div>
                
                
        
            
          </ul>
          
        </div>

       
      </div>
    </div>
  );
}

export default ServicosCard;
