import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Home from "./components/home/Home";
import ComoFunciona from "./components/ComoFunciona/ComoFunciona";
import Break from "./components/Break/Break";
import QuemSouEu from "./components/QuemSouEU/QuemSouEU";
import Servicos from "./components/servicos/Servicos";
import Faq from "./components/FAQ/FAQ";
import "./App.css";
import "./assets/styles/Reset.css";

import Sobre from "./components/Sobre/Sobre";

function App() {
  const main = process.env.REACT_APP_MAIN || '/';


  const inicio = useRef();
  const servicos = useRef();
  const contato = useRef();
  const sobre = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      inicio,
      servicos,
      contato,
      sobre
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "InicioRef") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  return (
    <Router>
      <div className="App">
        <Header scrollToComponent={scrollToComponent} />
        <div class="body-wrapper">
          <div ref={inicio}>
            <Home />
          </div>

          <Break />
<div ref={sobre}>
  
            <QuemSouEu/>
</div>

          {/* <ComoFunciona /> */}
<div ref={servicos}>
  
            <Servicos/>
</div>

          <Faq />
          <div ref={contato}>
            
            <Sobre />
          </div>
        </div>
        <div >
          <Footer scrollToComponent={scrollToComponent} />
        </div>

        <div className="whats-btn">
          <a
            href="https://wa.me/5584994416676"
            target="_blank"
            rel="noreferrer"
          >
            <i class="uil uil-whatsapp whats"></i>
          </a>
        </div>
        
      </div>
    </Router>
  );
}

export default App;
