import React, {useMemo} from "react";
import "./Footer.css";
import menuItems from "./Btns-Content";
import MenuBtn from "./MenuBtn";

const Footer = ({ scrollToComponent }) => {
  const action = (address) => {
    if (address !== "Link") {
      scrollToComponent(address);
    } else {
      scrollUp();
    }
  };

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const memoizedMenuItems = useMemo(() => {
    return menuItems.map((menuItem, index) => (
      <MenuBtn
        className="footer-menu-btn"
        action={action}
        data={menuItem}
        scrollToComponent={scrollToComponent}
      />
    ));
  }, [menuItems]);
  const main = process.env.REACT_APP_MAIN;

  return (
    <div className="footer__container">
      <div className="footer">
        <div className="footer__child">
          <div>
            <h3>Sobre</h3>
            <p>
              Destaque-se como advogado: produza conteúdo de qualidade para
              aumentar sua visibilidade e valor. Conte comigo para impulsionar
              sua presença de forma estratégica, ética e consistente.
            </p>
          </div>
        </div>

  
      </div>

      <div className="copy__container">
        <div className="copy">
          {" "}
          <p className="luana-name">
            Desenvolvido por{" "}
            <a
              href="https://wa.me/5584996060201"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Luana Salmito
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
