import React from "react";
import "./QuemSouEU.css";
import Dani from "../../assets/imgs/danisobre.svg";

function QuemSouEU() {
  return (
    <div className="QuemSouEU-container">
      <div className="dani-img">
        <img src={Dani} alt="danielle" />
      </div>
      <div className="quem-texts">
        <div className="main-titles">
          <h1>
            Prazer, sou{" "}
            <span className="dani-name" style={{ color: "#AA7049" }}>
              {" "}
              Danielle.
            </span>
          </h1>
          <span style={{ color: "#857164" }}>
            {" "}
            Serei sua estrategista de conteúdo!{" "}
          </span>
        </div>
        <p>
          Jornalista graduada pela Universidade Federal do Rio Grande do Norte
          (UFRN), já atuei em Assessorias de Imprensa e Agências de Marketing,
          onde enxerguei de perto o poder da comunicação na produção de sentido
          e criação de <span style={{ color: "#AA7049" }}> conexão</span> genuína.
        </p>

        <p>
          Fascinada por esse universo, decidi direcionar minha <span style={{ color: "#AA7049" }}> experiência</span>  para
          auxiliar profissionais do Direito.
        </p>

        <p>
          Por meio do <span style={{ color: "#AA7049" }}> Marketing Jurídico</span>, meu propósito é gerar <span style={{ color: "#AA7049" }}>valor</span> para a
          carreira desses profissionais, contribuindo para a construção de um
          setor jurídico mais eficiente e acessível.
        </p>
        <div>
          <a href="https://wa.me/5584994416676">
            <img src="" alt="" />
            <a href="https://www.instagram.com/DAANIELLE.LIMA/">
              <img src="" alt="" />
            </a>
          </a>
        </div>
      </div>
    </div>
  );
}

export default QuemSouEU;
