import React from "react";
import "./Home.css";
import MainFoto from "../../assets/imgs/mainimg.png";

function Home() {

  const falecomigo = "Olá! Estou interessado no seu serviço de Social Media, poderia me dar mais informações?";
  return (
    <div className="home-container">
      
        <div className="home-content">
            <h1>Social Media para Advogados</h1>
            <p className="main-title">
            Sua <span style={{ color: "#AA7049" }}> presença </span> nas redes sociais não é mais uma questão de escolha, é a chave para construir <span style={{ color: "#AA7049" }}>  conexões </span> e agregar <span style={{ color: "#AA7049" }}> valor  </span> a sua advocacia! 
          
        </p>

        <a className="main-btn" href={`https://wa.me/5584994416676text=${encodeURIComponent(falecomigo)}" }`}
            target="_blank"
            rel="noreferrer">Converse comigo agora</a>
          </div>
      
      <div className="img-container">
        <img className="main-img" src={MainFoto} alt="danielle" />
      </div>
    </div>
  );
}

export default Home;
