
import React from "react";
import "./Break.css";
import Visibilidade from "../../assets/imgs/1.svg"
import Estrategia from "../../assets/imgs/22.svg"
import Crescimento from "../../assets/imgs/3.svg"
import Etica from "../../assets/imgs/222.svg"
import Economia from "../../assets/imgs/2222.svg"

function Break() {
  return (
    <div className="Break-container">
      <div className="porque-precisa-content">
      <div className="title-sessao">
        <h1 className="main-title-como">É isso que o meu trabalho proporciona a você:</h1>
         
      </div>
        <ul>
          <div className="motivos">
            <li className="visibilidade">
              <div className="titulo-e-img-visibilidade">
                <img src={Visibilidade} alt="visibilidade" />
                <h3>Visibilidade</h3> 
              </div>
              <p className="explicacao">Presença contínua nas redes sociais, assegurando que o público seja sempre nutrido com a sua mensagem.</p>
            </li>
            <li className="gray">
            <div className="titulo-e-img">
                <img className="estrategias" src={Estrategia} alt="estrategias" />
                <h3>Estratégias Personalizadas</h3> </div> <p className="explicacao">Planejamento estratégico personalizado de acordo com sua área de atuação e as características da sua audiência.</p>
            </li>
            <li className="visibilidade">
            <div className="titulo-e-img">
                <img src={Crescimento} alt="crescimento" />
                <h3>Crescimento</h3> </div> <p className="explicacao">Conteúdo que educa, cativa e qualifica o público-alvo, elevando sua autoridade no campo jurídico.</p>
            </li>
          </div>
          <div className="motivos">
            <li className="gray">
              <div className="titulo-e-img">
                <img src={Economia} alt="visibilidade" />
                <h3>Economia de tempo</h3> 
              </div>
              <p className="explicacao">Permite que você se concentre no atendimento dos seus clientes e gestão do escritório;</p>
            </li>
            <li className="visibilidade">
            <div className="titulo-e-img">
                <img className="estrategias" src={Etica} alt="estrategias" />
                <h3>Ética</h3> </div> <p className="explicacao">
                Alinhamento com o Código de Ética da OAB e o Provimento 205/2021; </p>
            </li>
      
          </div>
        </ul>
      </div>
      
   </div> 
      
   
  );
}

export default Break;
