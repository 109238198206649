import React from "react";
import "./Sobre.css";
import Insta from "../../assets/imgs/insta.svg";
import Fine from "../../assets/imgs/fineline.png";


function Sobre() {
  return (
    <div className="Sobre-container">
      <div className="textos">
        <h1 className="titulo">Impulsione sua advocacia com conteúdos de valor para as suas redes sociais.</h1>

        <div className="ctt-insta">
          <a
            href="https://wa.me/5584994416676"
            target="_blank"
            rel="noreferrer"
          >
            <div>
              {" "}
              <button className="main-btn">Fale comigo no WhatsApp</button>
            </div>
          </a>
        </div>
        <a href="https://www.instagram.com/daanielle.lima/"
            target="_blank"
            rel="noreferrer">
          <div className="insta">
            <img className="logo-insta" src={Insta} alt="insta" />
            <p className="nomeinsta">@daanielle.lima</p>
          </div>
        </a>
      </div>
     
    </div>
  );
}

export default Sobre;
